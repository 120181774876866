/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix");
require("@rails/actiontext");

var Trix = require("trix");

/* what the newly created button does */
Trix.config.textAttributes.underline = {
  style: { "textDecoration": "underline" },
  inheritable: true,
  parser: function(element) {
    var style = window.getComputedStyle(element);
    return style.textDecorationLine === "underline";
  }
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" data-trix-key="u" title="Underline" tabindex="-1">Underline</button>';
  event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", buttonHTML);
});
